<template>
  <div class="details_product_body">
    <div class="myCoupons" >
      <div class="myCoupons_header">
        <h1>我的优惠券</h1>
        <span>-￥{{curCouponvalue}}</span>
      </div>
      <div class="myCoupons_body">
        <div class="usecoupons">
          <div class="coupons" v-for="(item, index) in couponlist" :key="index" :class="item.couponcode==couponcode?'active':''" @click="couponChange(item.couponcode,item.couponvalue)">
            <div class="coupons_content">
              <h2>{{ item.couponvalue }} <span>元</span></h2>
              <p>优惠券</p>
            </div>
            <div class="coupons_footer">
              <!-- <span>有效期至{{item.enddate}}</span> -->
              <span>{{ item.threshold_notice }}</span>
            </div>
          </div>
         <a-icon type="left-circle" class="left" @click="scrollLeft" v-if="couponlist.length>0"/>
          <a-icon type="right-circle" class="right" @click="scrollRight" v-if="couponlist.length>0"/>
        </div>
        <div class="combined">
          <span>已使用优惠券，节省{{curCouponvalue}}元</span>
          <h2>合计：<span>￥{{discount(tplList.discount_price-curCouponvalue)}}</span></h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCouponlist } from "@/api/manage";

export default {
  data() {
    return {
      couponlist: [],
    //   curCouponcode:'',
      curCouponvalue:0,
    };
  },
  props: {
    tcode: {
      type: String,
      default: "",
    },
    couponcode:{
      type: String,
      default: "",
    },
    total:{
      type: Number,
      // default: "",
    },
    tplList: {
      type: Object,
      default() {
        return [];
      },
    },
  },
  mounted() {

    this.getCoupon();
  },
  methods: {
    scrollRight(){
      var usecoupons = document.querySelector('.usecoupons')
      usecoupons.scrollLeft+=100
    },
    scrollLeft(){
      var usecoupons = document.querySelector('.usecoupons')
      usecoupons.scrollLeft-=100
    },
    getCoupon() {
      getCouponlist({
        pageSize: 100,
        pageNo: 1,
        isoverdue: 1,
        tcode: this.tcode,
      }).then(({ data }) => {
        this.couponlist = data.data;
      });
    },
    couponChange(couponcode,couponvalue){
        // this.couponcode=couponcode
        this.$emit('update:couponcode',couponcode)
        this.curCouponvalue=couponvalue
    },
    discount(unitprice) {
      let discount_price = unitprice;
        if (discount_price.toFixed(2) < discount_price) {
          discount_price = Number(discount_price.toFixed(2)) + 0.01;
        } else {
          discount_price = Number(discount_price.toFixed(2));
        }

      this.$emit('update:total',discount_price>0?discount_price:0)

      return discount_price>0?discount_price:0;
    },
  },
};
</script>

<style lang="less" scoped>
.details_product_body {
  margin-top: 25px;
  .myCoupons {
    margin: 30px 0;
    .myCoupons_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 24px;
      h1 {
        font-weight: 700;
      }
      span {
        color: #fc592d;
      }
    }
    .myCoupons_body {
             position: relative;
      .usecoupons {
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        .left{
          position: absolute;
          top: 30%;
          left: -25px;
          font-size: 25px;
          color: #1890ff;
          cursor: pointer;
        }
        .right{
            position: absolute;
          top: 30%;
          right: -25px;
            font-size: 25px;
            color: #1890ff;
            cursor: pointer;
        }
        .coupons {
          height: 180px;
          width: 140px;
          flex-shrink: 0;
          background: url(../../../assets/img/youhuijuan.png) no-repeat;
          text-align: center;
          padding: 25px 9px;
          margin-right: 15px;
          cursor: pointer;
          &.active{
              background:url(../../../assets/img/use-youhuijuan.png) no-repeat
          }
          .coupons_content {
            border-bottom: 1px dashed #999;
            padding: 10px 0;
            h2 {
              color: #fc592d;
              font-size: 35px;
              font-weight: 700;
              margin-bottom: 0;
              span {
                font-size: 12px;
                color: #333;
              }
            }
            p {
              background-color: #fc592d;
              color: #fff;
              padding: 3px 10px;
              border-radius: 10px;
              font-size: 14px;
              margin-right: 8px;
              text-align: center;
              width: 70%;
              margin: 0 auto;
            }
          }
          .coupons_footer {
            margin-top: 15px;
            font-size: 12px;
            color: #999;
          }
        }
      }
      .combined {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e1e3eb;
        padding: 15px 0;
        span {
          color: #a5aec5;
        }
        h2 {
          color: #333333;
          font-weight: 700;
          span {
            color: #fc592d;
            font-size: 24px;
          }
        }
      }
    }
  }
}
</style>