<template>
  <div class="details">
    <div class="details_box">
      <div class="details_header">
        <span> 模板中心 ></span
        ><span>
          {{
            tplList.prodid == 1
              ? "海报"
              : tplList.prodid == 2
              ? "H5"
              : tplList.prodid == 3
              ? "表单"
              : ""
          }}
          ></span
        >
        <h5>{{ tplList.tname }}</h5>
      </div>
      <div class="details_body">
        <div class="details_preview">
          <perviewdetail :prodid="tplList.prodid" :tcode="tplList.tcode" :name="tplList.tname"/>
        </div>
        <div class="details_product">
          <div class="details_product_header">
            <div class="product_name">
              <h2>{{ tplList.tname }}</h2>
              <div class="product_usenumber">
                <i class="iconbianji iconfont"></i>
                <span>{{ tplList.nowcosttimes }}</span>
              </div>
            </div>
            <div class="product_tags">
              <span v-for="item in tfnameList" :key="item">{{ item }}</span>
            </div>
            <div class="product_price">
              <h3>
                价格: <span>￥{{tplList.discount_price}}</span>
              </h3>
              <p v-if="member.memberState">-￥{{ tplList.unitprice }}</p>
            </div>
          </div>
          <slot name="couponsRender"></slot>
          <slot name="payRender"></slot>

          <div class="details_product_footer">
            <span>模板分享</span>
            <div class="share_box">
              <div class="share_content">
                <a-input class="share_input" v-model="sharelink"></a-input>
                <div class="share " @click="copy">复制链接</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters } from "vuex";
import Clipboard from "clipboard";
import perviewdetail from '../PerviewModal/perviewdetail.vue'
export default {
  data() {
    return {
      sharelink: process.env.VUE_APP_DESIGNER,
      // tcode: null,
      // tplList: [],
      tfnameList: [],
    };
  },
  components:{
    perviewdetail
  },
  props: {
    tplList: {
      type: Object,
      default() {
        return [];
      },
    },
  },
  mounted() {
    console.log(this.tcode);
    this.sharelink = this.sharelink+ 'xedesign/' +(this.tplList.prodid===1?'hb/preview/':this.tplList.prodid===2?'h5/showtemplates':'form/prew') + '?tcode='+this.tplList.tcode
  },
  methods: {
     copy() {
      let txt = this.sharelink;
      let clipboard = new Clipboard(".share", {
        text: function () {
          return txt;
        },
      });
      clipboard.on("success", (e) => {
        console.log(e);

        this.$message.info("复制成功");
        //  释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        console.log(e);
        // 不支持复制
        this.$message.info("该浏览器不支持复制");
        // 释放内存
        clipboard.destroy();
      });
    },


  },
  computed: {
    ...mapGetters(["tplflagList", "member"]),
  },
  // watch:{
  //     $route:{
  //         handler:function(val){
  //             this.tcode = val.query.tcode
  //             this.getTplinfo(val.query.tcode)
  //         },immediate:true,deep:true
  //     }
  // }
};
</script>

<style lang="less" scoped>
.details {
  width: 100%;
  height: 100%;
  .details_box {
    width: 70%;
    margin: 0 auto;
     height: 100%;
    .details_header {
      margin-bottom: 30px;
      span {
        color: #a6aec3;
        margin-right: 5px;
      }
      h5 {
        display: inline-block;
      }
    }
    .details_body {
      background-color: #fff;
      width: 100%;
      height: 700px;
      box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.3);
      display: flex;
      .details_preview {
        width: 50%;
        background-color: #f6f7fb;
        padding: 10px 0;
        position: relative;
      }
      .details_product {
        width: 50%;
        padding: 0 30px;
        .details_product_header {
          margin-top: 35px;
          border-bottom: 1px solid #e1e3eb;
          .product_name {
            display: flex;
            justify-content: space-between;
            h2 {
              font-size: 24px;
              font-weight: 700;
            }
            .product_usenumber {
              color: #333;
              i {
                margin-right: 10px;
              }
            }
          }
          .product_tags {
            span {
              border: 1px solid #fc592d;
              color: #fc592d;
              padding: 3px 3px;
              border-radius: 3px;
              font-size: 16px;
              margin-right: 8px;
            }
          }
          .product_price {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 18px 0;
            h3 {
              font-size: 18px;
              font-weight: 700;
              margin-bottom: 0;
              span {
                font-size: 24px;
                color: #fc592d;
              }
            }
            p {
              margin-bottom: 0;
              font-size: 18px;
              color: #a5aec5;
              text-decoration: line-through;
            }
          }
        }
        .pay_content {
          display: flex;
          margin-top: 10px;
          .pay {
            flex: 1;
            display: flex;
            justify-content: space-around;
            .pay_btn {
              flex: 1;
              margin-right: 10px;
            }
            .members_btn {
              flex: 1;
              margin-right: 10px;
              background-image: linear-gradient(to left, #eac59b, #ffebd2);
              border: none;
              color: #333;
            }
            i {
              margin-right: 5px;
            }
          }
        }
        .details_product_footer {
          margin-top: 30px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          span {
            color: #999;
          }
          .share_box {
            width: 80%;
            display: inline-block;
            background-color: #f6f7fb;
            border-radius: 5px;
            .share_content {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 0 0 10px;
              .share_input {
                border: none;
                background-color: #f6f7fb;
                color: #999;
              }
              .share {
                background-color: #0081ff;
                border-radius: 0 5px 5px 0;
                width: 80px;
                flex-shrink: 0;
                height: 100%;
                text-align: center;
                color: #fff;
                line-height: 40px;
                cursor: pointer;
              }
            }
          }
          .share_input:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
</style>