<template>
  <div>
    <ProductDetails v-if="tplList != ''" :tplList="tplList">
      <template v-slot:couponsRender>
        <details-coupon
          :couponcode.sync="couponcode"
          :total.sync="total"
          :tplList="tplList"
        ></details-coupon>
      </template>
      <template v-slot:payRender>
        <div class="pay_content">
          <div class="pay">
            <a-button
              type="primary"
              class="pay_btn"
              size="large"
              @click="wxpay"
            >
              立即{{total!=0?'支付':'使用'}}
            </a-button>
            <!-- 如果是会员，不显示会员按钮 -->
            <a-button
              type="primary"
              class="members_btn"
              size="large"
              @click="memberpay"
              v-if="!member.memberState"
            >
              <i class="t-icon-VIP t-icon"></i> 升级会员
              <div class="tips">
                预估会员价：{{ discount(tplList.unitprice, true) }}
              </div>
            </a-button>
          </div>
        </div>
      </template>
    </ProductDetails>
  </div>
</template>

<script>
import ProductDetails from "@/components/ProductDetails";
import detailsCoupon from "./components/detailsCoupon";
import wxPayModal from "@/components/wxPayModal";
import usercenter from "@/views/usercenter/usercenter.vue";
// import Login from "@/views/user/Login";
import { payTpl } from "@/api/pay";
import { gettplinfo } from "@/api/home";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tcode: "",
      couponcode:"",
      total:0, //计算最后的付款金额
      tplList:[],
      tfnameList: [],
    };
  },
  components: {
    ProductDetails,
    detailsCoupon,
  },
  computed: {
    ...mapGetters([ "member","tplflagList"]),
  },
  mounted() {
    this.tcode = this.$route.params.tcode;
    this.getTplinfo(this.tcode);
    console.log(this.tcode);
  },
  methods: {
      getTplinfo(tcode) {
      let parameter = {
        tcode: tcode,
      };
      gettplinfo(parameter).then((res) => {
        if (res.code === 200) {
          this.tplList = res.data;
          this.tplList.discount_price=this.discount(this.tplList.unitprice,this.member.memberState)
          this.findTifName(this.tplList.tfids.split(","));
          console.log(this.tplList);
        }
      });
    },
    findTifName(val) {
      this.tfnameList.length = 0;
      for (var i = 0; i < val.length; i++) {
        for (var j = 0; j < this.tplflagList.length; j++) {
          if (val[i] == this.tplflagList[j].tfid) {
            this.tfnameList.push(this.tplflagList[j].tfname);
          }
        }
      }
    },
    discount(unitprice,Boolean) {
      let discount_price = unitprice;
      let Discount=this.member.Discount!=undefined?this.member.Discount:95
      console.log(this.member.Discount)
      if (Boolean) {//如果是会员，不过期
        discount_price = unitprice * Discount * 0.01;
        console.log(discount_price.toFixed(2));
        if (discount_price.toFixed(2) < discount_price) {
          discount_price = Number(discount_price.toFixed(2)) + 0.01;
        } else {
          discount_price = Number(discount_price.toFixed(2));
        }
      }

      return discount_price;
    },
    wxpay(mcid) {
      console.log(mcid);
      let _this = this;
      let parameter = {
        // xeucode:10086688,
        tcode: this.tcode,
        couponcode: this.couponcode,
        paytype:2
      };

      payTpl(parameter)
        .then((data) => {
   
            if(data.code===200 && _this.total>0){
                _this.$dialog(
                  wxPayModal,
                  // component props
                  {
                    payData: data.data.wxdata,
                    openDesigner:this.openDesigner,
                  
                  },
                  // modal props
                  {
                    width: 400,
                    footer: "", //确定取消不要
                    centered: true,
                    maskClosable: false,
                  }
                );
            }else if(data.code===200 && _this.total===0){
              this.openDesigner(data.data.workno,data.data.worktype)
            }

        })
        .catch(() => {});
      return;
    },
    openDesigner(workno,worktype){
    
        let url;
        let host=process.env.VUE_APP_PCDESIGNER;
        // let host='https://wpc.xuan1tech.com/';
        switch(worktype) {
									
          case 1:
            url=host+'xedesign/hb/?workno='+workno
             break;
          case 2:
            url=host+'xedesign/h5/?workno='+workno
             break;
       case 3:
        url=host+'xedesign/form/?workno='+workno
         break;
       default:
      //  this.$util.msg("参数错误",{icon:'icon'})
      
     } 
     console.log(url)
        // window.location.href = url;
        window.open(url);   
    },
    memberpay(){
        let _this = this;

          _this.$dialog(
            usercenter,
            // component props
            {
            //   payData: data.data.wxdata,
             
            },
            // modal props
            {
              width: 1000,
              footer: "", //确定取消不要
              centered: true,
              maskClosable: false,
            }
          );
           
    }
  },
};
</script>
<style scoped>
.members_btn {
  position: relative;
}
.tips {
  position: absolute;
  top: -36px;
  left: 40px;
  background-image: linear-gradient(to left, #eac59b, #ffebd2);
  padding: 2px 8px;
  border-radius: 4px;
  /* color: #fff; */
  font-size: 14px;
}
.tips::before {
  position: absolute;
  content: "";
  bottom: -8px;
  left: 50%;
  margin-left: -4px;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #f5d8b7;
  transform: rotate(180deg);
}
</style>